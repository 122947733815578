import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
  
  <div class="pa-3" id="tester">
   
      <mySnackBar               v-bind:MySnackBar="this.MySnackBar"></mySnackBar>
      <uploadingRebrandDialog   v-bind:uploadingRebrandDialog="this.uploadingRebrandDialog"></uploadingRebrandDialog>
      <vue-easy-lightbox :visible = "lightBoxVisible" :imgs = "lightBoxImage"  @hide = "hideLightBoxImage"></vue-easy-lightbox>
      
      <label class="d-none">
         <input id="file-input" v-on:change="cameraChange" type="file" accept="image/*" capture >
      </label>
      
      <label class="d-none">
         <input id="file-input_gallery" v-on:change="cameraChangeGallery" type="file" accept="image/x-png,image/jpeg,image/gif">
      </label>
      

      <v-container>
      <v-row class="pt-2 pa-1 mb-2 d-flex justify-space-between align-baseline">
              
          <div>Tank Re-brand</div>
            
            <div>   
            <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReviewUpload" class="mx-2" fab dark small color="red darken-3">
              <v-icon dark>mdi-file-pdf</v-icon>
            </v-btn>
          
            <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
              <v-icon dark>mdi-presentation-play</v-icon>
            </v-btn>
            
            </div>

      </v-row>
      </v-container>

     <v-row class="mt-0" justify="center">
      <v-expansion-panels class="expansion_panels">

        <!-- Instructions -- removed 9/3/2021
        <v-expansion-panel class="expansion_panel mb-1"> 
          <v-expansion-panel-header>Tank rebrand / information Instructions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="header text-justify">
            Annual Inspection Form for Liquid Installations
          </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      -->

        <!-- customerComponent -->
          <customer v-bind:customer = "customer" />
        <!-- customerComponent -->

        <!-- reBrand information -->
          <v-expansion-panel class="expansion_pane mb-1"> 
         <v-expansion-panel-header>Tank Rebrand Information</v-expansion-panel-header>
         <v-expansion-panel-content>

            <v-container >
              <v-row dense>
                    <v-col dense cols="4">
                      <v-text-field  outlined dense v-model= "reBrand.tankSize"        label="Tank Size"> </v-text-field>
                    </v-col>

                    <v-col dense cols="8">
                      <v-text-field  outlined dense v-model= "reBrand.serialNumber"    label="Serial Number"> </v-text-field>
                    </v-col>
              </v-row>
      
              <v-row dense>
                
                      <v-col dense cols="12" sm="6">
                        <v-text-field  outlined dense v-model= "reBrand.reTest"        label="Retest"> </v-text-field>
                      </v-col>

                      <v-col dense cols="12" sm="6">
                       <v-text-field  outlined dense v-model= "reBrand.pipeworkType"       label="Pipework Type"> </v-text-field>
                      </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field  outlined dense v-model= "reBrand.percentage"          label="Percentage"> </v-text-field>
                    </v-col>
          
              </v-row>
               </v-container >

              <v-container >

                      <v-row dense >
                        <v-radio-group  label="Next Test Due:" v-model="reBrand.nextTestDue" row>
                           <v-radio      label="X"     value="X"></v-radio>
                           <v-radio      label="XX"    value="XX"></v-radio>
                        </v-radio-group>
                    </v-row>

              </v-container>



              <v-container>
                    
                      <v-row dense>
                         <v-col dense cols="12" sm="4">
                           <v-checkbox dense v-model="reBrand.ecvPlaque" label="ECV Plaque"></v-checkbox>
                         </v-col>
                       
                         <v-col dense cols="12" sm="4">
                         <v-checkbox dense v-model="reBrand.earthLug" label="Earth Lug"></v-checkbox>  
                         </v-col>
                       
                         <v-col dense cols="12" sm="4">
                          <v-checkbox dense v-model="reBrand.tankStickers" label="Tank Stickers"></v-checkbox>
                         </v-col>
                         
                    </v-row>

              </v-container>

              <v-container>
                      <v-row dense>
                          <v-col cols="12">
                             <v-textarea clearable clear-icon="mdi-close-circle" outlined v-model="reBrand.comments" label="Comments:"> </v-textarea>
                         </v-col>
                    </v-row>
              </v-container>


        </v-expansion-panel-content>
          </v-expansion-panel>
        <!-- end tank Details -->

        <!-- Section 1 - Photos -->
        <v-expansion-panel class="dl_expansion_pane mb-1"> 
          <v-expansion-panel-header>Photos</v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-container>

              <v-row dense>

                <v-col  v-for="(image, index) in images" v-bind:key=index  cols="12" :sm="4">
                  <v-card>
                    <v-card-text>
 
                      <v-img
                      :src          = "image.imageUrl" 
                      :contain      = "true"
                      @click        = "showLightBoxImage(image)"
                      class         = "white--text align-end"
                      width         = "100%"
                      gradient      = "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                     <v-card-title v-text="image.title"></v-card-title>
                    </v-img>
                 
                    </v-card-text>
                 
                    <v-card-actions>
      
                      <v-btn
                        class="mx-2"
                        fab
                        @click="takePhotoCamera(image)"
                        dark
                        color="indigo"
                      >
                        <v-icon dark>
                          mdi-camera
                        </v-icon>
                      </v-btn>

                      <v-spacer></v-spacer>
      
                      <v-btn
                        fab small
                        @click="takePhotoGallery(image)"
                        dark
                        color="pink"
                      >
                        <v-icon dark>mdi-camera-document</v-icon>
                      </v-btn>

                      <v-spacer></v-spacer>
      
                    
                      <v-btn   @click="resetPhoto(image)" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    
                    </v-card-actions>

                  </v-card>
                </v-col>
            </v-row>

          </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>


        <!-- last one for space-->
                <v-expansion-panel class="expansion_panel mb-2"> 
                </v-expansion-panel>
        <!-- last one for space-->
        <!--
          <v-expansion-panel id = "spacer" class="dl_expansion_panel">
          </v-expansion-panel>
        -->
      </v-expansion-panels>
     </v-row>

  </div>

</template>

<script>

import {store}   from '../../main.js'
import Localbase from 'localbase'

export default {

   name     : "reBrand",
   formName : 'reBrand',

    destroyed () {
          store.consoleLog( "reBrand form destroyed");
          this.snapShot( 'create' );
    },
      
   created    (){ 
   
        store.consoleLog( "rebrand form created");
        this.customer  = JSON.parse( localStorage.getItem("equalResponseCustomer") );
        this.engineer  = JSON.parse( localStorage.getItem("equalResponseEngineer") );
        this.salesman  = JSON.parse( localStorage.getItem("equalResponseSalesman") );
        this.snapShot( 'restore' );
   
   },

   methods   : {

              snapShot( action ) {

                  const customer            =  this.customer['ACCOUNT'];
                  const key                 =  this.$options.formName;

                  if ( action == 'create') {
                       this.createSnapShot( customer, key );
                  } else if (action == 'restore' ) {
                       this.restoreSnapShot( customer, key );
                  }
              },

              createSnapShot( customer, key ){

                let snapShot             =  this.generateSnapShot();

                let db                   =  new Localbase( this.$dbName );
                db.collection( customer ).doc( key).set( snapShot )
                .then(response => { 
                   console.log( response)
                })
                .catch(error => {
                   console.log( error)
                })
              },

              restoreSnapShot( customer,key ){

                let db = new Localbase(  this.$dbName );
                db.collection(customer).doc( key ).get()
                .then( document => {
                  if ( document != null ) {
                         this.reBrand         = document.formReviewData.reBrand;
                         this.images          = document.formReviewData.photos;
                  }
                })
                .catch( error => {
                   console.log( error)
                });

              },

              showSnackBar( message = "You need to review the form first..."){
                  this.MySnackBar.text          = message;
                  this.MySnackBar.showImage     = "icon";
                  this.MySnackBar.show          = true;
                  setTimeout( () => { this.MySnackBar.show  = false }, 1000)
              },
    
              showUploadingRebrandDialog() {
                      this.uploadingRebrandDialog.showDialog       = true;
              },
    
              closeUploadingRebrandDialog() {
                          setTimeout( () => {
                                  this.uploadingRebrandDialog.pdfMessage          = null;
                                  this.uploadingRebrandDialog.photosMessage       = null;
                                  this.uploadingRebrandDialog.showDialog          = false;
                          }, 2000)
              },

              updateUploadingRebrandDialog( pdfMessage = null, photosMessage = null) {

                          if ( pdfMessage != null ) {
                               this.uploadingRebrandDialog.pdfMessage   = pdfMessage;
                          }

                          if ( photosMessage != null ) {
                               this.uploadingRebrandDialog.photosMessage = photosMessage;
                          }
              },

              generateSnapShot() {
                                let snapShot          =   { 
                                                              "formReviewName"      : "reBrand",
                                                              "formReviewURL"       : this.$restServer.reBrandURL,
                                                              "formReviewResponse"  : {},
                                                              "formReviewData"      : {  
                                                                                      "requestedResponse"   : "pdf",
                                                                                      "customer"            : this.customer,
                                                                                      "salesman"            : this.salesman,
                                                                                      "engineer"            : this.engineer,
                                                                                      "reBrand"             : this.reBrand,
                                                                                      "photos"              : this.images
                                                                                      } 
                                                            };
                                return snapShot;
              },
               
              generateLoggingObject() {
                     return  { 
                                "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                                "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                                "formName"    : this.formName 
                             };
              },
 
              formReviewUpload    : async function() {
                         
                         const formReviewResponse         = store.retrieveFormReviewResponse();
                         if (  formReviewResponse  ) {
              
                                 formReviewResponse['loggingObject']     = this.generateLoggingObject();
 
                                 this.showUploadingRebrandDialog();
    
                                    this.uploadingRebrandDialog['pdfMessage'] ="Uploading PDF";
                                      const res       = await this.formReviewUpLoadPDF   ( formReviewResponse );
                                    this.uploadingRebrandDialog['pdfMessage'] ="Uploaded PDF";
 
                                    this.uploadingRebrandDialog.photoUpload['status'] = "Uploading Photos...";
                                       const photos    = await this.getformPhotos         ( formReviewResponse );
                                       const uploaded  = await this.uploadFormPhotos      ( photos );
                                    this.uploadingRebrandDialog.photoUpload['status'] = "Uploaded Photos";

                                   store.resetFormReviewResponse();
                                   this.resetPhotoArray(); 
                              
                                   const customer       =  this.customer['ACCOUNT'];
                                   const formName       =  this.formName;
                                   store.removeDocument( customer, formName);

                                this.closeUploadingRebrandDialog();
               
                        } else {
                             this.showSnackBar("You need to review the form first...");
                        }
              }, 
        
              formReviewUpLoadPDF : async function( formReviewResponse ){
            
                                try {
                                     const url   =  this.$restServer.uploadPDFToDropBoxURL;
                                     const res   =  await this.$http.post( url, formReviewResponse );
                                     console.log(res);
                                     return true;
                                } catch (error) {
                                     console.log(error);
                                     return false;
                                }
        
              },

              getformPhotos       : async function( formReviewResponse){

                let customer = formReviewResponse.client;
                let key      = formReviewResponse.formName;

                try {
                  let db          = new Localbase( this.$dbName );
                  let doc         = await db.collection(customer).doc( key ).get();
                  return  doc.formReviewData.photos;
                } catch (error) {
                  console.log(error);
                  return  [];
                }
              },

              uploadPhoto         : async function (photo) {

                const customer   =  this.customer['ACCOUNT'];
                const formName   = "reBrand";          
                const fileName   =  photo.fileName;                

                let   formData     =  new FormData();
                const key          =  photo.fileName;
                const value        =  photo.imageUrl;
                formData.append( key, value);

                let url = `${this.$restServer.photoUpload}/${customer}/${formName}`;
                url     =  url.toLowerCase();

                let response = await  this.$http.post(url, formData, {
                     headers          : { 'Content-Type': 'multipart/form-data'},
                     onUploadProgress : ( progressEvent ) => {
                      
                      let percentCompleted = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                      this.uploadingRebrandDialog['loadedBytes']           = progressEvent.loaded;
                      this.uploadingRebrandDialog['totalBytes']            = progressEvent.total;
                      this.uploadingRebrandDialog['percentageCompleted']   = percentCompleted;
                    
                      const message  =  `"${fileName}, total: ${progressEvent.total}, Loaded : ${progressEvent.loaded}, Percentage : ${percentCompleted}`;
                      this.uploadingRebrandDialog.photoUpload[`${fileName}`] = message; 
                      console.log( message);
                    }   

                  }); 
                
                if (response.data.success) {
                    console.log("success", response.data );
                    return true;
                }

              },

              uploadFormPhotos    : async function( photos ) {
  
                const v = await Promise.all(
                  photos.map(  async ( photo ) => {
                      if (photo.isValid) {
                          this.uploadingRebrandDialog.photoUpload[photo.fileName] = 'Upload Started...'; 
                          await this.uploadPhoto(photo);
                      }
                  })
                );
                return v;
              },
      
              formReview(){

                        try {
      
                              this.snapShot( 'create'); 
                              
                              let db             =  new Localbase(  this.$dbName );
                              const customer     =  this.customer['ACCOUNT'];
                              const key          =  this.$options.formName;

                              db.collection(customer).doc( key ).get()
                              .then( (snapShot) => {
                                 if ( snapShot != null ) {
                                        
                                        const photos = snapShot.formReviewData.photos;
                                        snapShot.formReviewData.photos = photos.filter( ( photo ) => {
                                            photo.imageUrl = ( photo.isValid) ? photo.lowRes : "https://www.empty.com";
                                            delete photo.lowRes;
                                            return true;
                                        });

                                        this.$router.push( { name : "FormReview", "params" : snapShot } );
                                }

                              })
                        
                        } catch (e) {
                            alert(e);
                        }
              },

              takePhotoGallery     : function( image) {
                    this.selectedImage = image;
                    document.getElementById('file-input_gallery').click();
              },

              takePhotoCamera      : function ( image )  {
                    this.selectedImage = image;
                    document.getElementById('file-input').click();
              },

              resetPhoto           : function ( image)  {
                   
                    const id   = image.id;
                    const index = this.images.findIndex( (x) => {
                                  if ( x.id === id)
                                      return true;
                    }); 

                    this.images[index].imageUrl   = this.$restServer['blankPhoto'];
                    this.images[index].lowRes     = this.$restServer['blankPhoto'];
                    this.images[index].isValid    = false;

                    // always take a new snapshot
                    this.snapShot('create');

              },
           
              resetPhotoArray      : function ( )  {

                  this.images.forEach( (image) => {
                                       image.isValid  = false;
                                       image.imageUrl = this.$restServer['blankPhoto']; 
                                       image.lowRes   = this.$restServer['blankPhoto']; 
                  }); 

              },
 
              updateImagesArray( photoHighRes, photoLowRes ){

                    const id  = this.selectedImage.id;
                    var index = this.images.findIndex( (x) => {
                                  if ( x.id === id)
                                       return true;
                    }); 

                    // always set the imageUrl to HighRes  
                    this.images[index].lowRes    = photoLowRes;
                    this.images[index].imageUrl  = photoHighRes;
                    this.images[index].isValid     = true;

                    // also take a new snapshot
                    this.snapShot('create');
            
              },

              cameraChangeGallery    : async function() {
                                          
                                          try {
                                         
                                              var file     = document.getElementById("file-input_gallery");
                                              if (file.files.length > 0 ) {
                                               
                                                  let photo              = document.getElementById("file-input_gallery").files[0];
                                                  const photoHighRes     = await this.getPhotoDataBase64( photo  );
                                                  const photoLowRes      = await this.resizePhotoLowRes ( photoHighRes );
                                                  this.updateImagesArray( photoHighRes, photoLowRes );
                                                  document.getElementById('file-input_gallery').value = null;
                                              }
                      
                                          }catch (error) {
                                              console.log(error); 
                                          }
                         
              },
                      
              cameraChange         : async function() {
                                          
                    try {
                   
                        var file     = document.getElementById("file-input");
                        if (file.files.length > 0 ) {
                         
                            const photo           = file.files[0];
                            const photoHighRes    = await this.getPhotoDataBase64( photo        );
                            const photoLowRes     = await this.resizePhotoLowRes(  photoHighRes );
                            this.updateImagesArray( photoHighRes, photoLowRes );
                            document.getElementById('file-input').value = null;
                        }

                    }catch (error) {
                        console.log(error); 
                    }
   
              },
           
              getPhotoDataBase64   : function ( photo) {

                        return new Promise(function(resolve, reject) {
                          
                          const reader = new FileReader();
                          reader.readAsDataURL(photo);
                          
                          reader.addEventListener('load',  function(e) {

                              const image_base64 = e.target.result; 
                              resolve(image_base64);
           
                          });

                          reader.addEventListener('error', function(e) {
                             reject(e);
                          });

                        });
              },

              resizePhotoLowRes    : function ( photoBase64 ) {
              
                    return new Promise(function(resolve, reject) {

                          const img = new Image();    
                          img.addEventListener('load', function( e ) {

                                          var canvas1    = document.createElement('canvas');
                                          canvas1.width  = 256;
                                          canvas1.height = 256;
                                          var ctx    = canvas1.getContext('2d');
                                          var canvas = ctx.canvas ;
                                          var hRatio = canvas.width  / img.width    ;
                                          var vRatio = canvas.height / img.height  ;
                                          var ratio  = Math.min ( hRatio, vRatio );
                                          var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
                                          var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
                                          ctx.clearRect(0,0,canvas.width, canvas.height);
                                          ctx.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);
                                          var dataURL  = canvas.toDataURL();
                                          resolve(dataURL);
                          });
                          img.addEventListener('error', function(e) { 
                            reject(e);
                          });
                          
                          img.src = photoBase64;
                  
                    });
                
              },

              resizePhoto          : function ( image, ratio) {

                        return new Promise(function(resolve, reject) {
                          
                                const reader = new FileReader();
                                reader.readAsDataURL(image);
                                
                                reader.addEventListener('load',  function(e) {
                                    // Create new image element
                 
                                    const img = new Image();
                                     img.addEventListener('load', function() {
                                          var canvas1    = document.createElement('canvas');
                                          canvas1.width  = 320;
                                          canvas1.height = 240;
                                       
                                          var ctx    = canvas1.getContext('2d');
                                          var canvas = ctx.canvas ;
                                          var hRatio = canvas.width  / img.width    ;
                                          var vRatio = canvas.height / img.height  ;
                                          var ratio  = Math.min ( hRatio, vRatio );
                                          var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
                                          var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
                                          ctx.clearRect(0,0,canvas.width, canvas.height);
                                          ctx.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);

                                    
                                    // const w       = 640; //ele.width  * ratio;
                                    // const h       = 480; //ele.height * ratio;
                                    // 12-20-2021 lets use 800x600
                                    // 4/10/2024 use 1200x800 and png
                                    // const w       = ele.width;
                                    // const h       = ele.height;
                                    // canvas.width  = w;
                                    // canvas.height = h;
                                    // alert(`canvas width = ${canvas.width}, height = ${canvas.height}`)
                                    // context.drawImage(ele, 0, 0, w, h);
                                    // no resize needed
                                    // context.drawImage(ele, 0, 0, ele.width, ele.height);
                                    // 1.0.19 use jpeg quality
                                    // var dataURL          = canvas.toDataURL("image/jpeg", 1.0);
                                    // 1.0.22 use png
                                        var dataURL  = canvas.toDataURL();
                                        resolve(dataURL);
                                    });
                                     img.src = e.target.result;
                                });

                                reader.addEventListener('error', function(e) {
                                  reject();
                                });
                        });
              },

              showLightBoxImage    : function ( image) {
                    this.lightBoxImage          = image.imageUrl;
                    this.lightBoxVisible        = true
              },
     
              hideLightBoxImage    : function() {
                    this.lightBoxImage          =  null;
                    this.lightBoxVisible        =  false
              },
   },

   data() {
    return {
        
        MySnackBar             : { "show" : false, "text" : "Default Text", "showImage" : "icon"},
        loading                : false,
        uploadingRebrandDialog :  {
                                     "showDialog"              : false,
                                     "pdfMessage"              : null,
                                     "photoUpload"             :  {
                                                                     "status"                 :  null,
                                                                     "current-boiler-plate"   :  null,
                                                                     "appliance-position"     :  null,
                                                                     "ecv-valve"              :  null,
                                                                     "fuel-type"              :  null,
                                                                     "power-supply"           :  null,
                                                                     "building-work"          :  null,
                                                                     "additional-photo-1"     :  null,
                                                                     "additional-photo-2"     :  null,
                                                                     "additional-photo-3"     :  null,
                                                                     "additional-photo-4"     :  null,
                                                                     "additional-photo-5"     :  null,
                                                                  },
                                   },

        lightBoxVisible   : false,
        lightBoxImage     : null,
        selectedImage     : null,
        formName          : "reBrand",
        customer          : {},
        salesman          : {},
        engineer          : {} ,
        reBrand           : {
                            tankSize      : "",
                            serialNumber  : "",
                            reTest        : "",
                            nextTestDue   : "",
                            pipeworkType  : "",
                            percentage    : "",
                            ecvPlaque     : "",
                            earthLug      : "",
                            tankStickers  : "",
                            comments      : ""   
                            },

        images          : [
                          {id:1 ,  isValid: false,  fileName : "current-boiler-plate"   , title  : "Photo Data Plate" ,    description : "Existing Boiler Photo",    lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:2 ,  isValid: false,  fileName : "appliance-position"     , title  : "Photo Property" ,      description : "Appliance Position Photo", lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:3 ,  isValid: false,  fileName : "ecv-valve"              , title  : "Photo ECV" ,           description : "E.C.V Valve",              lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:4 ,  isValid: false,  fileName : "fuel-type"              , title  : "Photo Regulator" ,     description : "Fuel Type Photo",          lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:5 ,  isValid: false,  fileName : "power-supply"           , title  : "Photo Tank" ,          description : "Power Supply Photo",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:6 ,  isValid: false,  fileName : "building-work"          , title  : "Photo Survey" ,        description : "Building Work Photo",      lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:7 ,  isValid: false,  fileName : "additional-photo-1"     , title  : "Photo Additional 1" ,  description : "Additional Photo 1",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:8 ,  isValid: false,  fileName : "additional-photo-2"     , title  : "Photo Additional 2" ,  description : "Additional Photo 2",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:9 ,  isValid: false,  fileName : "additional-photo-3"     , title  : "Photo Additional 3" ,  description : "Additional Photo 3",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:10 , isValid: false,  fileName : "additional-photo-4"     , title  : "Photo Additional 4" ,  description : "Additional Photo 4",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          {id:11 , isValid: false,  fileName : "additional-photo-5"     , title  : "Photo Additional 5" ,  description : "Additional Photo 5",       lowRes: this.$restServer['blankPhoto'],   imageUrl: this.$restServer['blankPhoto'] },
                          ],
        };
  }
};


</script>

<style scoped>
h1,h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.dl_expansion_panel_content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.header{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
}

input[type=text] {
  width: 100%;
  padding: 8px 20px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

div.v-text-field__details {
  display: none;
}

legend.v-label {
  font-size: 16px !important;
}



</style>
       
